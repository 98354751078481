import React from "react";
import { graphql, Link } from "gatsby";

import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { StyledLink } from "baseui/link";

import { Paragraph1, Label1 } from "baseui/typography";

import Page from "../components/internal-page";
import BackButton from "../components/back-button";

const frpProgramsTemplate = ({
  data: {
    cooperativeProgramsFrp: {
      name,
      description,
      interestRate,
      loanTerm,
      minAmount,
      maxAmount,
      projectBudget,
      links,
      files,
    },
  },
}) => {
  // const okverdList = okved.allow.reduce((acc, val) => (acc += `${val}, `), "");
  const [css, theme] = useStyletron();
  const space = css({ marginLeft: theme.sizing.scale800 });
  return (
    <Page
      title={`Программа «${name}»`}
      description={`Совместная программа регионального и федерального Фондов развития промышленности - «${name}»`}
      parent="frp"
      path="/frp/programs"
    >
      <Label1>
        от {minAmount.toLocaleString("ru-RU")}&nbsp;&#8381; до{" "}
        {maxAmount.toLocaleString("ru-RU")}&nbsp;&#8381; для проектов с бюджетом
        от {projectBudget.toLocaleString("ru-RU")}
        &nbsp;&#8381;
      </Label1>
      <Paragraph1>
        Ставка: {interestRate}, Срок: до {loanTerm} мес.
      </Paragraph1>
      <Label1>Описание программы</Label1>
      <Paragraph1>
        {`Федеральный и региональные фонды предоставляют займы на реализацию проектов в рамках программы «${name}» в соотношении 70% (федеральные средства) на 30% (средства регионов).`}
      </Paragraph1>
      <Paragraph1>{description}</Paragraph1>
      <Paragraph1>
        <StyledLink
          href={links.related.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Программа «${name}» на сайте frprf.ru`}
        </StyledLink>
      </Paragraph1>
      {/* <Label1>Требования к заявителю</Label1>
      <Paragraph1> */}
      {/* <ul>
          <li>
            Быть зарегистрированным и осуществлять деятельность на территории
            Архангельской области;
          </li>
          <li>
            Деятельность относится к обрабатывающему производству и входит в
            следующий перечень кодов ОКВЭД: {okverdList.slice(0, -2)};
          </li>
          <li>
            Софинансирование собственных средств в размере не менее 15% от суммы
            Проекта;
          </li>
          <li>
            Отсутствие просроченной задолженности по уплате налогов, сборов, а
            также по заработной плате работникам и НДФЛ.
          </li>
        </ul> */}
      {/* </Paragraph1>
      <Label1>Целевое использование</Label1>
      <Paragraph1> */}
      {/* Проекты, направленные на технологическое перевооружения производств
        путем приобретения машин и оборудования, включая хозяйственный
        инвентарь, а также оплаты сопутствующих работ. */}
      {/* </Paragraph1>
      <Label1>Погашение займа</Label1>
      <Paragraph1> */}
      {/* <ul>
          <li>
            Проценты по займу уплачиваются ежеквартально, начиная с первого
            квартала после выдачи займа;
          </li>
          <li>
            {" "}
            погашение основной суммы займа производится ежеквартально равными
            долями в течение последних двух лет срока его предоставления.
          </li>
        </ul> 
      </Paragraph1>*/}
      <Label1>Приложения</Label1>
      {files.data.map(file => {
        return (
          <Paragraph1>
            <StyledLink
              href={file.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.title}
            </StyledLink>
          </Paragraph1>
        );
      })}
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1FX4eZ2gerWrXya6msXCVgRJc5n0kMXH-/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Дополнительные условия предоставления займов в 2022 году
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1iJD231_Xqfx8jLViISrA536-t_7BQ-_l/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок предоставления займов
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/17-p2CMcAs8CEbi5LjMaJ2l2XY5FzADEG/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок работы с заемщиками
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/19t0o30_CYO7bVbEIEpRt_XGqo9bNT_7l/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок работы с обеспеспечением займов
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/11t9tg5djfsZgwRmf2E8IEXtO6-IjvM_4/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок проведения экспертиз
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/16pjYYEC1GZsHVhFyoxTiH0I2G_nK2xxX/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок работы по мониторингу займов
        </StyledLink>
      </Paragraph1>
      <Label1>Подача заявки</Label1>
      <Paragraph1>
        Подача заявки по совместным и федеральным программам осуществляется
        онлайн через{" "}
        <StyledLink
          href="https://frprf.ru/informatsiya-dlya-zayaviteley/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Государственную информационную систему промышленности (ГИСП)
        </StyledLink>
      </Paragraph1>
      <div
        className={css({
          display: "flex",
          alignContent: "center",
        })}
      >
        <BackButton title="Назад" />
        <span className={space} />
        <Button
          $as={Link}
          to="https://lk.frprf.ru/"
          target="_blank"
          rel="noopener noreferrer"
          kind={KIND.primary}
        >
          Войти в ГИСП
        </Button>
      </div>
    </Page>
  );
};
export default frpProgramsTemplate;

export const query = graphql`
  query($slug: String) {
    cooperativeProgramsFrp(slug: { eq: $slug }) {
      name
      description
      interestRate
      loanTerm
      minAmount
      maxAmount
      projectBudget
      links {
        related {
          href
        }
      }
      files {
        data {
          title
          href
        }
      }
    }
  }
`;
